/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-26 11:00:07
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-26 11:14:50
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerStore = params => axios({
    url: '/api/firm/community/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerStore = params => axios({
    url: '/api/firm/community/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerStore = params => axios({
    url: '/api/firm/community/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerStore = params => axios({
    url:'/api/firm/community/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerStore = params => axios({
    url: '/api/firm/community/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listDealer = params => axios({
  url: '/api/dealer/store/dealerStore/listNotChooseStoreManager',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
